import React from 'react';
import { navigate } from 'gatsby';
import useAuth from '../../hooks/useAuth';
import inBrowser from '../../utils/inBrowser';

const AuthRoute = ({ component: Component, location, ...rest }) => {
    const { isAuthenticated, fetchUser } = useAuth();
    if (!isAuthenticated && location.pathname !== `/login`) {
        fetchUser();
        inBrowser && navigate('/login');
        return null;
    }

    return <Component {...rest} />;
};

export default AuthRoute;
